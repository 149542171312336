import "./styles.css";

import CtaForm from "../../components/CTAForm";

import header3_1 from "../../assets/header3_1.png";
import header3_2 from "../../assets/header3_2.png";
import header3_3 from "../../assets/header3_3.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <h1 className="title">
            Móveis e Projetos <b>EXCLUSIVOS para</b> <span>Farmácias</span>
          </h1>

          <CtaForm />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <h2 className="title">ENTREGA E MONTAGEM PARA TODO O BRASIL</h2>
        </article>
      </section>

      <section className="header3">
        <article className="container vertical">
          <h2 className="title">
            <span>NOSSOS </span> SERVIÇOS
          </h2>

          <p className="text">
            Há mais de 20 anos desenvolvendo projetos exclusivos em Farmácias.
          </p>

          <div className="gallery">
            <div className="item">
              <figure>
                <img src={header3_1} alt="Móveis Para Farmácias" />
              </figure>

              <div className="bottom">
                <p className="item_title">Móveis Para Farmácias</p>

                <p className="item_text">
                  Desenvolvemos projetos exclusivos e elegantes, de acordo com
                  sua estrutura e que valorizam os seus produtos para seu
                  cliente.
                </p>

                <CtaForm />
              </div>
            </div>

            <div className="item">
              <figure>
                <img src={header3_2} alt="Projetos 2D e 3D em Móveis" />
              </figure>

              <div className="bottom">
                <p className="item_title">Projetos 2D e 3D em Móveis</p>

                <p className="item_text">
                  O projeto é de fundamental importância para o entendimento do
                  cliente no momento da reforma ou instalação da nova loja
                </p>

                <CtaForm />
              </div>
            </div>

            <div className="item">
              <figure>
                <img src={header3_3} alt="Sua Farmácia Completa" />
              </figure>

              <div className="bottom">
                <p className="item_title">Sua Farmácia Completa</p>

                <p className="item_text">
                  Possuímos todo o conhecimento necesário para a melhor execução
                  do seu projeto. Dispomos de móveis e acessórios inovadores
                  para o sucesso do seu estabelecimento comercial.
                </p>

                <CtaForm />
              </div>
            </div>
          </div>
        </article>
      </section>
    </header>
  );
};

export default Header;
