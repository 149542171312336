import { useState } from "react";
import "./styles.css";

import icon from "../../assets/iconwhats.png";

const CtaForm = () => {
  const [text, setText] = useState("Solicitar Orçamento");

  return (
    <button id="ctaForm">
      <a
        href="http://wa.me/+5511914183386?text=Olá,%20Gostaria%20de%20solicitar%20um%20orçamento"
        target="_blank"
        rel="noreferrer"
      >
        <figure>
          <img src={icon} alt="Ícone do Formulário" />
        </figure>
        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaForm;
