import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.css";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import CtaForm from "../../components/CTAForm";

import about1 from "../../assets/about1.png";
import about3_1 from "../../assets/about3_1.png";
import about3_2 from "../../assets/about3_2.png";
import about3_3 from "../../assets/about3_3.png";
import about3_4 from "../../assets/about3_4.png";
import about3_5 from "../../assets/about3_5.png";
import about3_6 from "../../assets/about3_6.png";
import about3_7 from "../../assets/about3_7.png";
import about3_8 from "../../assets/about3_8.png";
import about3_9 from "../../assets/about3_9.png";
import about4 from "../../assets/about4.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: about3_1,
  },
  {
    id: 2,
    image: about3_2,
  },
  {
    id: 3,
    image: about3_3,
  },
  {
    id: 4,
    image: about3_4,
  },
  {
    id: 5,
    image: about3_5,
  },
  {
    id: 6,
    image: about3_6,
  },
  {
    id: 7,
    image: about3_7,
  },
  {
    id: 8,
    image: about3_8,
  },
  {
    id: 9,
    image: about3_9,
  },
];

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container horizontal">
          <div className="left">
            <h2 className="title">
              <span>Sobre</span> Nós
            </h2>

            <p className="text">SOMOS A LIDER MÓVEIS PARA FARMÁCIAS</p>

            <figure className="mobile">
              <img src={about1} alt="Móveis para Farmácias" />
            </figure>

            <p className="text2">
              Atuamos no mercado de construção de móveis para farmácias, desde
              1990 com a especialização em montagem de ambiente de Farmácias,
              Drogarias, Perfumarias, Lojas e Produtos e acessórios diversos.
              <br />
              <br />
              Preocupamo-nos em desenvolver produtos de qualidade e inovação,
              auxiliando na consultoria e criação de projetos, desenvolvendo-os,
              desde a planta baixa, passando para visualização 3D até a execução
              da obra. Oferecendo, assim, um layout ideal para cada segmento.
              <br />
              <br />
              Atendemos em todo o território nacional. Confira nossos Projetos
              para Farmácias e Lojas. Faça agora mesmo um orçamento conosco e
              veja a qualidade dos nossos serviços.
            </p>

            <CtaForm />
          </div>

          <figure className="right">
            <img src={about1} alt="Móveis para Farmácias" />
          </figure>
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <h2 className="title">ENTREGA E MONTAGEM PARA TODO O BRASIL</h2>
        </div>
      </article>

      <article className="about3">
        <div className="container vertical">
          <h2 className="title">
            <span>PROJETOS</span> ENTREGUES
          </h2>

          <p className="text">
            Veja alguns projetos que já entregamos aos nossos clientes.
          </p>

          <Swiper
            className="carousel"
            spaceBetween={20}
            slidesPerView={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? 1
                : 4
            }
            navigation
            loop
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {data.map(({ id, image }) => {
              return (
                <SwiperSlide key={id} className="img">
                  <figure>
                    <img src={image} alt={`Exemplo ${id} de Foto`} />
                  </figure>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <CtaForm />
        </div>
      </article>

      <article className="about4">
        <div className="container">
          <h2 className="title">
            <span>FORMAS DE </span> PAGAMENTO
          </h2>

          <p className="text">Cartão de crédito, Cartão BNDES e Cheque</p>

          <figure>
            <img src={about4} alt="FORMAS DE PAGAMENTO" />
          </figure>
        </div>
      </article>
    </section>
  );
};

export default About;
