import "./styles.css";

import CtaTel from "../../components/CTATel";
import CtaWpp from "../../components/CTAWpp";
import Form from "../../components/Form";

const FormPage = () => {
  return (
    <section id="formPage">
      <article className="formPage1">
        <div className="container vertical">
          <h2 className="title">
            <span>SOLICITE UM </span>ORÇAMENTO
          </h2>

          <p className="text">Preencha o formulário, entraremos em contato.</p>

          <Form />
        </div>
      </article>

      <article className="formPage2">
        <div className="container vertical">
          <h2 className="title">FALE CONoSCO agora mesmo</h2>

          <p className="text">por telefone ou WhatsApp</p>

          <div className="cta">
            <CtaTel />
            <CtaWpp />
          </div>
        </div>
      </article>
    </section>
  );
};

export default FormPage;
